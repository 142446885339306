import UnderConstruction from './UnderConstruction'


function App() {

  return (
    <UnderConstruction></UnderConstruction>
    );
}

export default App;
